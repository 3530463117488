import React from 'react';
import '../components/HomeView/Home.css'
import {TopMenuLinks} from "../components/HomeView/TopMenuLinks";
import {ExtendedNetworkMessage} from "../components/HomeView/ExtendedNetworkMessage";
import {ServiceSection} from "../components/HomeView/ServiceSection";
import {ReviewSection} from "../components/HomeView/ReviewSection";
import reviewPerson1Image from './../img/ReviewImages/Wrigley.jpg';
import reviewPerson2Image from './../img/ReviewImages/Lucy.jpg';
import reviewPerson3Image from './../img/ReviewImages/Abbie.jpg';
import {AboutMe} from "../components/HomeView/AboutMe";
import {ProcessSection} from "../components/HomeView/ProcessSection";
import JohnPic from "../img/John.jpg";
import {DownArrow} from "../components/HomeView/DownArrow";
import phone from "../img/phone.png";
import PlantBullet from './../img/plant.svg'
import Example1Before from './../img/WorkImages/example1_before.png'
import Example1After from './../img/WorkImages/example1_after.png'
import Example2Before from './../img/WorkImages/example2_before.png'
import Example2After from './../img/WorkImages/example2_after.png'
import {Carousel} from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import {WorkImages} from "../components/HomeView/WorkImages";
import ReactGA from "react-ga4"; // requires a loader


export const HomeView = () => {

    return <div className='BodyContainer'>
        <div className='RootImageContainer'>
            <div className='HeaderContainer'>
                <div className='LogoHeaderContainer'>
                    <div style={{
                        fontSize: 45,
                        fontFamily: 'Lobster',
                        color: '#ffffff',
                        // backgroundColor: 'rgba(38,29,13,0.53)',
                        // borderRadius: 50,
                        paddingRight: '4%',
                        paddingLeft: '4%',
                        textShadow: '-2px -2px 2px #6b5603, 2px -2px 2px #6b5603, -2px 2px 2px #d5ac08, 2px 2px 2px #d5ac08',

                        textAlign: 'center'
                    }}>
                        Deep Roots Tree Care
                    </div>
                    <div style={{
                        fontSize: 20,
                        fontFamily: 'Agbalumo',
                        color: '#edf3ea',
                        // backgroundColor: 'rgba(38,29,13,0.53)',
                        borderRadius: 50,
                        paddingRight: '4%',
                        paddingLeft: '4%',
                        textShadow: '-1px -1px 1px #6b5603, 1px -1px 1px #6b5603, -1px 1px 1px #d5ac08, 1px 1px 1px #d5ac08',

                    }}>
                        Locally Grown Since 2020
                    </div>
                </div>
                <div className='ContactUsContainer'>
                    <div style={{
                        fontSize: 18,
                        fontFamily: 'Optima',
                        textAlign: 'center',
                        marginRight: '10%',
                        marginLeft: '10%',
                        color: '#edf3ea'
                    }}>
                        I love meeting members of the community
                    </div>
                    <a
                        href='tel:+13098383195'
                        style={{
                            color: 'black',
                            fontSize: 22,
                            fontFamily: 'Optima',
                            border: '1px solid #d5ac08',
                            backgroundColor: '#7ae017',
                            textAlign: 'center',
                            borderRadius: 20,
                            padding: '5%',
                            marginTop: '5%',
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            // backgroundImage: 'linear-gradient(to right bottom, #6bef79, #55cd5d, #3eac42, #258d28, #046e0a)',
                            // backgroundSize: 'contain',
                            // background: 'rgb(2,0,36)',
                            // backgroundImage: 'linear-gradient(to right bottom, #6bef79, #55cd5d, #3eac42, #258d28, #046e0a)',
                            // background: 'linear-gradient(345deg, rgba(2,0,36,1) 0%, rgba(6,76,21,1) 22%, rgba(9,121,12,1) 35%, rgba(4,167,135,1) 71%, rgba(0,212,255,1) 100%)'
                            // backgroundImage: 'radial-gradient(circle, #48b575, #409b5f, #36824a, #2c6a37, #215325)'
                            // backgroundImage: 'radial-gradient(circle, #37905c, #32804d, #2d713f, #276232, #215325)'
                            backgroundImage: 'radial-gradient(circle, #37905c, #2d7f49, #236e36, #185e24, #0d4e12)'


                        }} onClick={() =>
                        ReactGA.event({
                            category: 'Button',
                            action: 'Contact Us (TOP)',
                            label: 'Contact Us (TOP)'
                        })}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <img style={{
                                width: 20,
                                height: 20,
                                display: "block",
                                marginRight: '5%',
                            }} src={phone}/>
                            <div style={{color: 'white', fontFamily: 'Lobster'}}>Let's Chat!</div>
                        </div>
                        <div style={{color: 'white', fontFamily: 'Lobster'}}>(309) 838-3195</div>
                    </a>
                </div>
            </div>
            <div className='ServicesContainer'>
                <div style={{
                    fontSize: 40,
                    fontFamily: 'Agbalumo',
                    color: '#edf3ea',
                    marginTop: '20%',
                    // textShadow: '-2px -2px 2px #6b5603, 2px -2px 2px #6b5603, -2px 2px 2px #073C08, 2px 2px 2px #073C08',
                }}>
                    Services
                </div>
                {/*<ServiceSection title={'Consultations'}*/}
                {/*                body={"I'll come out and take a look at your environment.  It's free and we can discuss what can be done to keep your trees growing happy and healthy"}/>*/}
                <ServiceSection title={'Tree Trimming and Pruning'}
                                body={"Even the healthiest trees can benefit from a haircut.  Your trees will thank you for removing dead branches, raising the drip edge, and giving them room to grow."}/>
                {/*<ServiceSection title={'Tree Pest and Disease Controll'}*/}
                {/*                body={"I'll come out and take a look at your wood.  It's free and we can discuss what can be done to keep your trees growing happy and healthy"}/>*/}
                {/*<ServiceSection title={'Emergency Mitigation'}*/}
                {/*                body={"I'll come out and take a look at your wood.  It's free and we can discuss what can be done to keep your trees growing happy and healthy"}/>*/}
                <ServiceSection title={'Tree Removal'}
                                body={"It's time to let that thing go. I'll remove trees of any size, then grind down the stump like it was never even there."}/>
                <ServiceSection title={'Firewood Delivery'}
                                body={"Let me fill your rack with the best wood around. Locally harvested and cured in crisp central Illinois air for the finest burn."}/>

                <ServiceSection title={'Emergency Storm Work'}
                                body={"Did Mother Nature knock some things loose? I'll clean up the mess"}/>

            </div>
        </div>
        <div className='ReviewsContainer'>
            <div style={{fontSize: 40, fontFamily: 'Agbalumo', color: '#edf3ea'}}>
                What People Say
            </div>
            <ReviewSection image={reviewPerson1Image} name={'Wrigley F.'}
                           review={"John trimmed a number of my owner's trees, and even found the perfect stick for me!"}/>
            <ReviewSection image={reviewPerson2Image} name={'Lucy P.'}
                           review={"John made my favorite tree disappear!  It looks great, but now I have to find a new place to pee."}/>
            <ReviewSection image={reviewPerson3Image} name={'Abbie F.'}
                           review={"I'm always happy to see John! He must be popular, because he always smells like other dogs."}/>
        </div>
        <div className='LocationsContainer'>
            <div style={{fontSize: 40, fontFamily: 'Agbalumo', color: '#edf3ea'}}>
                Serving Central IL
            </div>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '5%'}}>
                <ul style={{
                    fontSize: 20,
                    fontFamily: 'Rajdhani',
                    fontWeight: 'bold',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <li>Bloomgington</li>
                    <li>Carlock</li>
                    <li>Chenoa</li>
                    <li>Clinton</li>
                    <li>Danvers</li>
                    <li>Downs</li>
                    <li>El Paso</li>
                    <li>Funks Grove</li>
                    <li>Gridley</li>
                </ul>
                <ul style={{fontSize: 20, fontFamily: 'Rajdhani', fontWeight: 'bold'}}>
                    <li>Heyworth</li>
                    <li>Hudson</li>
                    <li>Le Roy</li>
                    <li>Lexington</li>
                    <li>Merna</li>
                    <li>Minier</li>
                    <li>Normal</li>
                    <li>Shirley</li>
                    <li>Towanda</li>
                </ul>
            </div>
        </div>
        <div className='AboutMeContainer'>
            <AboutMe/>
        </div>
        <div className='WorkImagesContainer'>
            <WorkImages/>
        </div>
        <div className='ProcessContainer'>
            <div style={{fontSize: 40, fontFamily: 'Agbalumo', color: '#edf3ea', marginBottom: '5%'}}>
                The Process
            </div>
            <ProcessSection stepNumber={1} title={'Contact Me'} body={''}/>
            <DownArrow/>
            <ProcessSection stepNumber={2} title={'Talk About Trees'}
                            body={"I'll answer any questions you may have about tree care."}/>
            <DownArrow/>
            <ProcessSection stepNumber={3} title={'Free Consultation'}
                            body={"I'll come to your property to get an exact idea of your needs.  We will discuss what maintenance may be currently needed, and what may need to be done in the future."}/>
            <DownArrow/>
            <ProcessSection stepNumber={4} title={'All of Your Problems are Solved*'}
                            body={'Whether it is a simple removal or scheduled care, you will find yourself in a more peaceful living space.'}/>
            {/*<div style={{fontFamily: 'Rajdhani', marginTop: '15%', marginLeft: '5%', marginRight: '5%',  fontSize: 18}}>* Legally, I*/}
            {/*    can only promise to solve your tree-related problems. The rest of them are still on you, but I'm a great*/}
            {/*    listener.*/}
            {/*</div>*/}
        </div>
    </div>
}

