import React, {useEffect, useState} from 'react'
import JohnPic from './../../img/John5.png'
import Example1Before from "../../img/WorkImages/example1_before.png";
import Example1After from "../../img/WorkImages/example1_after.png";
import Example2Before from "../../img/WorkImages/example2_before.png";
import Example2After from "../../img/WorkImages/example2_after.png";
import {Carousel} from "antd";
import '../HomeView/Home.css';

export const WorkImages = (props) => {

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    return <div style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginTop: '5%',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: screenSize.width < 800 ? '20%' : '10%',
    }}>
        <div style={{display: 'flex', flex: 1, fontSize: 40, fontFamily: 'Agbalumo', color: '#edf3ea', marginBottom: '5%'}}>
            The Proof
        </div>
        <Carousel style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: screenSize.width < 600 ? screenSize.width : 600,
            height: screenSize.width < 600 ? screenSize.width : 600,
            background: 'red',
        }} autoplay>
            <div style={{maxWidth: screenSize.width}}>
                <img style={{width: screenSize.width < 600 ? screenSize.width : 600,
                    height: screenSize.width < 600 ? screenSize.width : 600,}} src={Example1Before}/>
                <p className="legend" style={{color: '#4b6446'}}>Emergency Removal (Before)</p>
            </div>
            <div style={{
                maxWidth: screenSize.width
            }}>
                <img style={{width: screenSize.width < 600 ? screenSize.width : 600,
                    height: screenSize.width < 600 ? screenSize.width : 600}} src={Example1After}/>
                <p className="legend" style={{color: '#4b6446'}}>Emergency Removal (After)</p>
            </div>
            <div style={{maxWidth: screenSize.width}}>
                <img style={{width: screenSize.width < 600 ? screenSize.width : 600,
                    height: screenSize.width < 600 ? screenSize.width : 600}} src={Example2Before}/>
                <p className="legend" style={{color: '#3c5446'}}>Overgrowth Removal (Before)</p>
            </div>
            <div style={{maxWidth: screenSize.width}}>
                <img style={{width: screenSize.width < 600 ? screenSize.width : 600,
                    height: screenSize.width < 600 ? screenSize.width : 600}} src={Example2After}/>
                <p className="legend" style={{color: '#3c5446'}}>Overgrowth Removal (After)</p>
            </div>
        </Carousel>
    </div>
}

