import React from 'react'
import JohnPic from './../../img/John5.png'
export const AboutMe = (props) => {

    return <div style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '5%',
        justifyContent: 'center'
    }}>
        <div style={{fontSize: 40, fontFamily: 'Agbalumo', color: '#edf3ea', marginBottom: '5%'}}>
            The Owner
        </div>
        <img style={{borderRadius: "50%",
            border: '4px solid #886d06',
            width: 140,
            height: 140,
            filter: 'drop-shadow(1mm 1mm 1mm rgba(25,25,25, .5))',
            display: "block"}} src={JohnPic} />
        <div style={{
            display: 'flex',
            fontSize: 28,
            color: '#d5ac08',
            fontFamily: 'Lobster',
            filter: 'drop-shadow(1mm 1mm 1mm rgba(25,25,25, .5))'
        }}>John Dick</div>
        <div style={{
            display: 'flex',
            fontSize: 18,
            color: '#d5ac08',
            fontFamily: 'Rajdhani',
            fontWeight: 'bold',
            marginBottom: '2.5%',
            textAlign: 'center',
        }}>Insured Tree Care Specialist</div>
        <div style={{
            display: 'flex',
            fontSize: 18,
            color: '#dff3e2',
            fontFamily: 'Rajdhani',
            fontWeight: 'bold',
            marginTop: '7.5%',
            marginBottom: '5%',
            textAlign: 'center',
            marginRight: '10%',
            marginLeft: '10%',
        }}>Helping others is a core value in both my personal and professional lives.  Having been climber and outdoorsman most of my life, I have a great appreciation for nature's beauty.  It is important to me to make sure that every person I interact with leaves feeling like they are better informed and more confident about what their trees need to be happy.</div>
    </div>
}

