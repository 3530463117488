import React from 'react'

export const ReviewSection = (props) => {

    return <div style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '5%',
        justifyContent: 'center'
    }}>
        <img style={{borderRadius: "50%",
            border: '2px dashed white',
            width: 80,
            height: 80,
            display: "block",
            filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'
        }} src={props.image} />
        <div style={{
            display: 'flex',
            fontSize: 18,
            // color: '#dff3e2',
            color: '#d5ac08',
            fontFamily: 'Rajdhani',
            fontWeight: 'bold',
            filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'
        }}>{props.name}</div>
        <div style={{
            display: 'flex',
            fontSize: 18,
            color: '#dff3e2',
            fontFamily: 'Rajdhani',
            fontWeight: 'bold',
            marginTop: '2.5%',
            marginBottom: '2.5%',
            textAlign: 'center',
            marginRight: '10%',
            marginLeft: '10%',
        }}>"{props.review}"</div>
    </div>
}

