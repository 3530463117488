import React from 'react'
import JohnPic from './../../img/John.jpg'
import phone from "../../img/phone.png";
import ArrowDown from "../../img/arrowDown.svg";
import '../HomeView/Home.css'
import ReactGA from "react-ga4";

export const ProcessSection = (props) => {

    const body = () => {
        if (props.title === 'Contact Me') {
            return <div style={{width: '100%'}}>
                <div>{props.body}</div>
                <a
                    href='tel:+13098383195'
                    className='LetsChatButton'
                    style={{
                        color: 'black',
                        fontSize: 22,
                        fontFamily: 'Optima',
                        border: '1px solid #d5ac08',
                        // backgroundColor: '#7ae017',
                        textAlign: 'center',
                        borderRadius: 20,
                        padding: '5%',
                        marginTop: '10%',
                        marginBottom: '10%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        // background: 'rgb(2,0,36)',
                        // background: 'linear-gradient(345deg, rgba(2,0,36,1) 0%, rgba(6,76,21,1) 22%, rgba(9,121,12,1) 35%, rgba(4,167,135,1) 71%, rgba(0,212,255,1) 100%)'
                        backgroundImage: 'radial-gradient(circle, #37905c, #2d7f49, #236e36, #185e24, #0d4e12)'

                    }}
                    onClick={() =>
                        ReactGA.event({
                            category: 'Button',
                            action: 'Contact Us (BOTTOM)',
                            label: 'Contact Us (BOTTOM)'
                        })}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <img style={{
                            width: 20,
                            height: 20,
                            display: "block",
                            marginRight: '5%'
                        }} src={phone}/>
                        <div style={{color: 'white'}}>Lets Chat!</div>
                    </div>
                    <div style={{color: 'white'}}>(309) 838-3195</div>
                </a>
            </div>
        } else if(props.title === 'All of Your Problems are Solved*') {
            return <div style={{
                width: '100%'
            }}>
                <div>{props.body}</div>
                <div style={{
                    display: 'flex',
                    fontSize: 16,
                    fontFamily: 'Rajdhani',
                    marginTop: '7.5%',
                    textAlign: 'left',
                    // background: 'red',
                    borderRadius: 20,
                    padding: '2.5%',
                    color: '#0bbe26'
                }}>*Legally, I
                    can only promise to solve your tree-related problems. The rest of them are still on you, but I'm a great
                    listener.</div>
            </div>
        } else {
            return props.body
        }
    }

    return <div style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#02340a',
        borderRadius: 20,
        border: '2px solid #d5ac08',
        // borderBottomLeftRadius: 0,
        // borderBottomRightRadius: 0,
        filter: 'drop-shadow(1mm 1mm 1mm rgb(25,25,25))',
        width: '70%',
    }}>
        <div style={{
            display: 'flex',
            fontSize: 24,
            color: '#02340a',
            backgroundColor: '#d0b950',
            width: '100%',
            borderTopRightRadius: 18,
            borderTopLeftRadius: 18,
            // justifyContent: 'center',
            // textAlign: 'center',
            fontFamily: 'Agbalumo'
        }}>
            <div style={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                marginRight: '3%',
                justifySelf: 'left',
                fontFamily: 'Agbalumo',
                marginLeft: '5%'
                // backgroundColor: 'red',
            }}>{props.stepNumber}</div>
            <div style={{}}>
            {props.title}
            </div>
        </div>
        <div style={{
            display: 'flex',
            fontSize: 18,
            color: '#dff3e2',
            fontFamily: 'Rajdhani',
            marginTop: '5%',
            marginBottom: '5%',
            textAlign: 'left',
            marginRight: '10%',
            marginLeft: '10%',
        }}>{body()}</div>
    </div>
}

