import React, { Component } from 'react';
import './App.css';
import DeepRootsWebsite from './pages/DeepRootsWebsite'
import ReactGA from "react-ga4";
ReactGA.initialize("G-QGV9GZ5DHW");

class App extends Component {
  render() {
    return (
      <div style={{ height: '100%'}}>
        <DeepRootsWebsite/>
      </div>
    );
  }
}

export default App;
