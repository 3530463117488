import React from 'react'
import JohnPic from './../../img/John.jpg'
import ArrowDown from "../../img/arrowDown.svg";
export const DownArrow = (props) => {

    return <div style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '2.5%',
        marginBottom: '2.5%',
        justifyContent: 'center',
        borderRadius: 20,
    }}>
        <img style={{borderRadius: "50%",
            width: 30,
            height: 30,
            display: "block"}} src={ArrowDown} />
       </div>
}

