import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {HomeView} from "./HomeView";

const DeepRootsWebsite = () => {

    return (
        <div style={{height: '100%'}}>
            <BrowserRouter>
                    <Switch>
                        <Redirect exact from="/index.html" to="/"/>
                        <Route path="/" exact component={HomeView}/>
                    </Switch>
            </BrowserRouter>
        </div>
    )
}

export default DeepRootsWebsite
